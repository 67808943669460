const akkordeon = document.querySelectorAll('.ce_rsce_akkordeon');

if (akkordeon.length > 0){
    akkordeon.forEach(akkordeon => {
        let akkordeonItem = akkordeon.querySelectorAll('.item');
        akkordeonItem.forEach(item => {

            item.addEventListener('click', function(){
                console.log('test')
                if (item.classList.contains('active')){
                    item.classList.remove('active');
                } else {
                    item.classList.add('active');
                }
                
            });
        });
    });
   
}