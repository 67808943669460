const menu = document.querySelector('.container-inner.mobile');

if (menu) {

    let menuButton = menu.querySelector('.menu-button');

    let menuOpenButton = menu.querySelectorAll('.submenu');

    menuButton.addEventListener('click', ()=> {
        menu.classList.toggle('active');
    });

    menuOpenButton.forEach((button) => {
        button.addEventListener('click', (e) => {
            button.classList.toggle('open');
        });
    });
}