require("./blockmapsfonts.js");

require('./ki-model.js');

require('./akkordeon.js');

require('./menu.js');



/*
const AOS = require("aos");

AOS.init({
    duration: 600,
    easing: 'ease-out-sine',
    startEvent: 'load'
});

document.querySelectorAll("img").forEach(img => {
    img.addEventListener("load", () => {
        AOS.refresh();
    })
})
*/
