const uploadSection = document.querySelectorAll('.ce_rsce_uploadSection');
const uploadSectionInput = document.querySelector('#imageUpload');
const predictionOutput = document.querySelector('#output');

// Create overlay element
const overlay = document.createElement("div");
overlay.id = "loadingOverlay";
overlay.innerHTML = '<div class="overlay-content">Processing image, please wait...</div>';
document.body.appendChild(overlay);

const URL = "./files/Dateien/model/";
let model, maxPredictions;

if (uploadSection.length > 0) {

    async function loadModel() {
        if (!model) {
            const modelURL = URL + "model.json";
            const metadataURL = URL + "metadata.json";

            model = await tmImage.load(modelURL, metadataURL);
            maxPredictions = model.getTotalClasses();
        }
    }

    uploadSectionInput.addEventListener('change', function(event) {
        loadImage(event);
    });

    async function loadImage(event) {
        const file = event.target.files[0];
        const reader = new FileReader();

        if (file.size / 1024 < 50) {
            alert('File Quality is too bad, take another one');
            return;
        }

        showOverlay(); // Show loading overlay
        await loadModel();

        reader.onload = async function() {
            const image = new Image();
            image.src = reader.result;
            image.onload = async function() {
                const predictions = await predict(image);
                displayPredictions(predictions);
                hideOverlay(); // Hide overlay when done
            };
        };
        reader.readAsDataURL(file);
    }

    async function predict(imageElement) {
        if (!model) {
            console.error("Model not loaded!");
            return [];
        }
        return await model.predict(imageElement);
    }

    function displayPredictions(predictions) {
        predictionOutput.innerHTML = "";

        let maxProbability = -1;
        let mostProbableClass = "";

        for (let i = 0; i < predictions.length; i++) {
            let className = predictions[i].className;
            let output = predictions[i].probability.toFixed(2) * 100;
            output = parseInt(output);

            if (output > maxProbability) {
                maxProbability = output;
                mostProbableClass = className;
            }

            if (output > 1) {
                let html = '<div class="output-print"><p>' + className + '% Bodyfat ' + '</p><div class="output-inner"><div class="output-fill" style="width: ' + output + '%"></div><div class="output-amount">' + output + '%' + '</div></div></div>';
                predictionOutput.innerHTML += html;
            }
        }
        document.querySelector('.resultBox').classList.add('active');
        uploadFile(mostProbableClass);
    }

    function uploadFile(folderName) {
        var formData = new FormData();
        var fileInput = document.getElementById('imageUpload');
        formData.append('file', fileInput.files[0]);   
        formData.append('folder', folderName);
    
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                if (xhr.status !== 200) {
                    document.getElementById('status').innerHTML = 'Error uploading file.';
                }
            }
        };
        xhr.open('POST', 'https://bodyfat-calculator.com/upload.php', true);
        xhr.send(formData);
    }

    function showOverlay() {
        overlay.style.display = "flex";
    }

    function hideOverlay() {
        overlay.style.display = "none";
    }
}

// CSS for overlay
const style = document.createElement("style");
style.innerHTML = `
    #loadingOverlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        display: none;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 18px;
        font-weight: bold;
        z-index: 1000;
    }
    .overlay-content {
        background: rgba(0, 0, 0, 0.8);
        padding: 20px 40px;
        border-radius: 8px;
        box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
    }
`;
document.head.appendChild(style);
