const uploadSection = document.querySelectorAll('.ce_rsce_uploadSection');
const uploadSectionInput = document.querySelector('#imageUpload');

const predictionOutput = document.querySelector('#output');

if (uploadSection.length > 0){

    const URL = "./files/Dateien/model/";

    let model, labelContainer, maxPredictions;

    async function loadModel() {
        const modelURL = URL + "model.json";
        const metadataURL = URL + "metadata.json";

        model = await tmImage.load(modelURL, metadataURL);
        maxPredictions = model.getTotalClasses();
    }

    uploadSectionInput.addEventListener('change', function(event){
        loadImage(event);
    })
    
    async function loadImage(event) {
        const file = event.target.files[0];
        const reader = new FileReader();

        if (file.size / 1024 < 50) {
            alert('File Quality is to bad, take another one');
            return;
        }

        reader.onload = async function() {
            const image = new Image();
            image.src = reader.result;
            image.onload = async function() {
                const predictions = await predict(image);
                displayPredictions(predictions);
            }
        };
        reader.readAsDataURL(file);
    }
    
    async function predict(imageElement) {
        const prediction = await model.predict(imageElement);
        return prediction;
    }
    
    function displayPredictions(predictions) {
        predictionOutput.innerHTML = "";

        let maxProbability = -1; // Initialisieren Sie die maximale Wahrscheinlichkeit mit einem negativen Wert
        let mostProbableClass = ""; // Initialisieren Sie den wahrscheinlichsten Klassennamen

        for (let i = 0; i < predictions.length; i++) {
            let className = predictions[i].className;
            let output = predictions[i].probability.toFixed(2) * 100;
            output = parseInt(output);

            if (output > maxProbability) { // Wenn die aktuelle Wahrscheinlichkeit größer als die maximale ist
                maxProbability = output; // Aktualisieren Sie die maximale Wahrscheinlichkeit
                mostProbableClass = className; // Aktualisieren Sie den wahrscheinlichsten Klassennamen
            }

            if (output > 1) {
                let html = '<div class="output-print"><p>' + className + '% Bodyfat ' + '</p><div class="output-inner"><div class="output-fill" style="width: ' + output + '%"></div><div class="output-amount">' + output + '%' + '</div></div></div>';
                predictionOutput.innerHTML = predictionOutput.innerHTML + html;
            }
        }
        document.querySelector('.resultBox').classList.add('active');
        uploadFile(mostProbableClass);
    }
    
    loadModel();
    

    function uploadFile(folderName) {
        var formData = new FormData();
        var fileInput = document.getElementById('imageUpload');
        formData.append('file', fileInput.files[0]);   
        formData.append('folder', folderName);
    
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    
                } else {
                    document.getElementById('status').innerHTML = 'Error uploading file.';
                }
            }
        };
        xhr.open('POST', 'https://bodyfat-calculator.com/upload.php', true);
        xhr.send(formData);
    }
}










/*
    */